<template>
  <div
    class="min-h-screen lg:flex flex-col justify-center bg-logan-rose/50 p-8"
  >
    <div class="max-w-6xl mx-auto">
      <!-- <h1
        class="text-balance text-5xl font-black tracking-tight text-logan-green sm:text-7xl"
      >
        Logan AI
      </h1> -->
      <img
        :src="require('@/assets/logo-logan.png')"
        alt="Logan AI"
        class="w-48 h-auto mx-auto"
      />
      <p
        class="mt-8 text-pretty text-lg font-medium text-logan-black sm:text-xl/8"
      >
        An AI-powered platform that revolutionizes CRUD operations and live
        dataset queries. Logan AI bridges the gap between natural language
        interactions and robust backend integrations, enabling seamless data
        management and real-time insights.
      </p>
      <!-- Main Layout -->
      <div class="grid grid-cols-1 lg:grid-cols-5 gap-4 mt-8">
        <!-- AI Chat Section -->
        <div class="lg:col-span-2 bg-white/80 shadow-md rounded-xl p-4">
          <h2 class="text-xl font-semibold mb-4">Logan Chat</h2>
          <p class="text-sm text-gray-600 mb-4">
            Ask Logan questions in natural language and watch it seamlessly
            interact with live data from the backend. Whether you're querying
            the database or performing actions, Logan provides instant and
            intelligent responses powered by advanced AI.
          </p>
          <div
            ref="chatBox"
            class="chat-box h-96 overflow-y-auto bg-white border border-gray-300 rounded-xl p-4 mb-4"
          >
            <!-- <div class="mb-4 chat-bubble flex justify-end">
              <div
                :class="{
                  'text-right text-white py-2.5 px-3 rounded-2xl shadow-sm max-w-[75%] bg-[#948d95] text-sm':
                    testMessage.role === 'user',
                  'text-left text-logan-black': testMessage.role === 'system',
                }"
              >
                <p class="mb-1 font-black">
                  {{ testMessage.role === "user" ? "You" : "Logan" }}:
                </p>
                <p>{{ testMessage.content }}</p>
              </div>
            </div>
            <div
              class="mb-4 chat-bubble flex justify-start items-center text-sm"
            >
              <img
                v-if="testSystemMessage.role === 'system'"
                :src="require('@/assets/speech.png')"
                alt="Logan"
                class="h-8 w-auto mr-3 p-[1px] bg-black dark:bg-white/25 rounded-full"
              />
              <div
                :class="{
                  'text-right text-white py-2.5 px-3 rounded-2xl shadow-sm max-w-[75%] bg-[#948d95] text-sm':
                    testSystemMessage.role === 'user',
                  'text-left text-logan-black':
                    testSystemMessage.role === 'system',
                }"
              >
                <p class="mb-1 font-black">
                  {{ testSystemMessage.role === "user" ? "You" : "Logan" }}:
                </p>
                <p>{{ testSystemMessage.content }}</p>
              </div>
            </div> -->
            <div
              v-for="(message, index) in chatMessages"
              :key="index"
              class="mb-4 chat-bubble text-sm"
              :class="{
                'flex justify-end': message.role === 'user',
                'flex justify-start items-center': message.role === 'system',
              }"
            >
              <img
                v-if="message.role === 'system'"
                :src="require('@/assets/speech.png')"
                alt="Logan"
                class="h-8 w-auto mr-3 p-[1px] bg-black dark:bg-white/25 rounded-full"
              />
              <div
                :class="{
                  'text-right text-white py-2.5 px-3 rounded-2xl shadow-sm max-w-[75%] bg-[#A68F97] text-sm':
                    message.role === 'user',
                  'text-left text-logan-black': message.role === 'system',
                }"
              >
                <!-- <p class="mb-1 font-black">
                  {{ message.role === "user" ? "You" : "Logan" }}:
                </p> -->
                <p>{{ message.content }}</p>
              </div>
            </div>
            <div v-if="isLoganThinking" class="flex justify-start">
              <TypingBounce />
            </div>
          </div>

          <form @submit.prevent="sendMessage" class="flex flex-col">
            <label
              for="message"
              class="block text-sm font-medium leading-6 text-gray-900 sr-only"
              >Message</label
            >
            <div class="relative rounded-md shadow-sm">
              <canvas
                v-show="recognizing"
                ref="waveformCanvas"
                class="waveform-canvas"
              ></canvas>
              <input
                ref="messageInput"
                v-model="inputMessage"
                type="text"
                name="message"
                id="message"
                autocomplete="off"
                class="block w-full rounded-xl border-0 py-2 pr-20 text-gray-900 dark:text-white dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#1a615f] dark:focus:ring-[#4d8482] sm:text-sm sm:leading-6"
                placeholder="Ask Logan a question..."
                aria-describedby="message-description"
                :class="{ 'pl-11': recognizing }"
              />
              <div
                class="absolute inset-y-0 right-0 flex items-center pr-4 gap-x-3"
              >
                <button
                  @click.prevent="toggleRecognition"
                  title="Start Recognition"
                  type="button"
                >
                  <MicrophoneIcon
                    v-if="!recognizing"
                    class="h-5 w-5 text-gray-500 hover:text-logan-green"
                    aria-hidden="true"
                  />
                  <StopCircleIcon
                    v-else
                    class="h-5 w-5 text-logan-green animate-pulse"
                    aria-hidden="true"
                  />
                </button>
                <button title="Send Message" type="submit">
                  <PaperAirplaneIcon
                    class="h-5 w-5 text-gray-500 hover:text-logan-green"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- Data Table Section -->
        <div class="lg:col-span-3 bg-white/80 shadow-md rounded-xl p-4">
          <h2 class="text-xl font-semibold mb-4">A live database view</h2>
          <p class="text-xs text-gray-600 mb-4">
            The data displayed here is fetched in real-time from a live
            DigitalOcean database. Every action performed or query made updates
            this view instantly, showcasing the power of AI-driven backend
            integration.
          </p>
          <div
            class="overflow-y-auto max-h-[450px] border border-gray-300 rounded-md"
          >
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs uppercase bg-gray-200 text-gray-700">
                <tr>
                  <th class="py-2 px-4">First Name</th>
                  <th class="py-2 px-4">Last Name</th>
                  <th class="py-2 px-4">Email</th>
                  <th class="py-2 px-4">Status</th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr
                  v-for="user in users"
                  :key="user.id"
                  class="border-b hover:bg-gray-100"
                >
                  <td class="py-2 px-4">{{ user.first_name }}</td>
                  <td class="py-2 px-4">{{ user.last_name }}</td>
                  <td class="py-2 px-4">{{ user.email }}</td>
                  <td
                    class="py-2 px-4"
                    :class="{
                      'text-green-500': user.status === 'Active',
                      'text-yellow-500': user.status === 'Pending',
                      'text-red-500': user.status === 'Inactive',
                    }"
                  >
                    {{ user.status }}
                  </td>
                </tr>
                <tr v-if="users.length === 0">
                  <td colspan="4" class="text-center py-2 px-4 text-gray-500">
                    No user accounts available.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  MicrophoneIcon,
  PaperAirplaneIcon,
  PlusIcon,
  StopCircleIcon,
} from "@heroicons/vue/24/outline";
import apiClient from "../api/apiClient";
import TypingBounce from "@/components/animations/TypingBounce.vue";

export default {
  components: {
    MicrophoneIcon,
    PaperAirplaneIcon,
    PlusIcon,
    TypingBounce,
    StopCircleIcon,
  },
  data() {
    return {
      users: [],
      chatMessages: [],
      inputMessage: "",
      isListening: false,
      recognition: null,
      conversationId: null,
      recognizing: false,
      isLoganThinking: false,
      audioStream: null,
      testMessage: {
        role: "user",
        content: "Hello, Logan!",
      },
      testSystemMessage: {
        role: "system",
        content: "Hello, User!",
      },
    };
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await apiClient.get("/user-accounts/index");
        // Correctly access users array and map status to labels
        this.users = response.data.data.map((user) => ({
          ...user,
          status:
            user.status === 1
              ? "Active"
              : user.status === 2
              ? "Inactive"
              : "Pending",
        }));
      } catch (error) {
        console.error("Error fetching users:", error);
        alert("Failed to load user accounts.");
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatBox = this.$refs.chatBox;
        if (chatBox) {
          chatBox.scrollTop = chatBox.scrollHeight;
        }
      });
    },
    async sendMessage() {
      if (!this.inputMessage.trim()) return;

      // Add user message to chat
      this.chatMessages.push({ role: "user", content: this.inputMessage });
      const userInput = this.inputMessage;
      this.inputMessage = "";

      try {
        this.isLoganThinking = true;
        const response = await apiClient.post("/handle_request", {
          input_text: userInput,
          user_id: "1", // Mocked user ID
          microservice: "user_accounts",
          conversation_id: this.conversationId, // Send the conversation ID if it exists
        });

        // Save the conversation ID if it's provided
        if (response.data.conversation_id) {
          this.conversationId = response.data.conversation_id;
        }
        this.isLoganThinking = false;
        // Add AI response to chat
        this.chatMessages.push({
          role: "system",
          content: response.data.response,
        });

        // Refresh user data if updated
        this.fetchUsers();
      } catch (error) {
        console.error("Error sending message:", error);
        this.chatMessages.push({
          role: "system",
          content: "An error occurred while processing your request.",
        });
      }
      this.scrollToBottom();
      // Clear input field
    },
    toggleRecognition() {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          this.audioStream = stream; // Save the MediaStream

          // Initialize Speech Recognition
          if (!this.recognition) {
            const SpeechRecognition =
              window.SpeechRecognition || window.webkitSpeechRecognition;

            if (!SpeechRecognition) {
              alert("Speech Recognition API not supported in this browser.");
              return;
            }

            this.recognition = new SpeechRecognition();
            this.recognition.continuous = true;
            this.recognition.interimResults = true;
            this.recognition.lang = "en-US";

            this.recognition.onstart = () => {
              this.recognizing = true;
              this.startWaveformVisualization(stream);
            };

            this.recognition.onend = () => {
              this.recognizing = false;
              this.stopWaveformVisualization();
              this.stopAudioStream(); // Stop the audio stream when recognition ends
            };

            this.recognition.onresult = (event) => {
              let interimTranscript = "";
              let finalTranscript = "";

              for (let i = event.resultIndex; i < event.results.length; i++) {
                if (event.results[i].isFinal) {
                  finalTranscript += event.results[i][0].transcript;
                } else {
                  interimTranscript += event.results[i][0].transcript;
                }
              }

              this.inputMessage = finalTranscript || interimTranscript;
            };

            this.recognition.onerror = (event) => {
              console.error("Speech recognition error:", event.error);
            };
          }

          // Start/Stop Recognition
          if (this.recognizing) {
            this.recognition.stop();
          } else {
            this.recognition.start();
          }
        })
        .catch((error) => {
          console.error("Microphone access denied:", error);
          alert("Please enable microphone access to use this feature.");
        });
    },
    stopAudioStream() {
      if (this.audioStream) {
        this.audioStream.getTracks().forEach((track) => track.stop());
        this.audioStream = null; // Clear the audio stream reference
      }
    },
    startWaveformVisualization(stream) {
      if (!this.audioContext) {
        this.audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
      }

      const source = this.audioContext.createMediaStreamSource(stream);
      this.analyser = this.audioContext.createAnalyser();
      source.connect(this.analyser);

      this.analyser.fftSize = 2048;
      const bufferLength = this.analyser.frequencyBinCount;
      this.dataArray = new Uint8Array(bufferLength);

      const canvas = this.$refs.waveformCanvas;
      const canvasContext = canvas.getContext("2d");

      const drawWaveform = () => {
        this.analyser.getByteTimeDomainData(this.dataArray);

        canvasContext.fillStyle = "#f3f3f3";
        canvasContext.fillRect(0, 0, canvas.width, canvas.height);

        canvasContext.lineWidth = 2;
        canvasContext.strokeStyle = "#004F4D";

        canvasContext.beginPath();

        const sliceWidth = (canvas.width * 1.0) / bufferLength;
        let x = 0;

        for (let i = 0; i < bufferLength; i++) {
          const v = this.dataArray[i] / 128.0;
          const y = (v * canvas.height) / 2;

          if (i === 0) {
            canvasContext.moveTo(x, y);
          } else {
            canvasContext.lineTo(x, y);
          }

          x += sliceWidth;
        }

        canvasContext.lineTo(canvas.width, canvas.height / 2);
        canvasContext.stroke();

        this.animationFrameId = requestAnimationFrame(drawWaveform);
      };

      drawWaveform();
    },
    stopWaveformVisualization() {
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId);
        this.animationFrameId = null;
      }

      if (this.audioContext) {
        this.audioContext.close().then(() => {
          this.audioContext = null; // Clear the AudioContext reference
        });
      }
    },
  },
  mounted() {
    this.fetchUsers();
  },
  beforeUnmount() {
    if (this.recognizing && this.recognition) {
      this.recognition.stop(); // Stop recognition
    }
    this.stopAudioStream(); // Stop MediaStream
    this.stopWaveformVisualization(); // Stop and close AudioContext
  },
  watch: {
    chatMessages() {
      this.scrollToBottom();
    },
  },
};
</script>

<style scoped>
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chat-bubble {
  animation: slideIn 0.3s ease-out;
}
.waveform-canvas {
  position: absolute;
  top: 50%;
  left: 0.75rem;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  /* filter: drop-shadow(0 0 6px rgba(0, 128, 255, 0.7)); */
}
.mic-button.active {
  color: #004f4d;
  animation: pulse 1s infinite;
}
/* .user-message {
  background: linear-gradient(135deg, #e6eded, #f2f1f2, #f6f4f5);
} */

.dark .user-message {
  background: linear-gradient(to right, #1c1f26, #2a2e38);
}
</style>
